/*
 * Copyright (C) 2019 Zippie Ltd.
 * 
 * Commercial License Usage
 * 
 * Licensees holding valid commercial Zippie licenses may use this file in
 * accordance with the terms contained in written agreement between you and
 * Zippie Ltd.
 * 
 * GNU Affero General Public License Usage
 * 
 * Alternatively, the JavaScript code in this page is free software: you can 
 * redistribute it and/or modify it under the terms of the GNU Affero General Public
 * License (GNU AGPL) as published by the Free Software Foundation, either
 * version 3 of the License, or (at your option) any later version.  The code
 * is distributed WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU AGPL for
 * more details.
 * 
 * As additional permission under GNU AGPL version 3 section 7, you may
 * distribute non-source (e.g., minimized or compacted) forms of that code
 * without the copy of the GNU GPL normally required by section 4, provided
 * you include this license notice and a URL through which recipients can
 * access the Corresponding Source.
 * 
 * As a special exception to the AGPL, any HTML file which merely makes
 * function calls to this code, and for that purpose includes it by reference
 * shall be deemed a separate work for copyright law purposes.  In addition,
 * the copyright holders of this code give you permission to combine this
 * code with free software libraries that are released under the GNU LGPL.
 * You may copy and distribute such a system following the terms of the GNU
 * AGPL for this code and the LGPL for the libraries.  If you modify this
 * code, you may extend this exception to your version of the code, but you
 * are not obligated to do so.  If you do not wish to do so, delete this
 * exception statement from your version.
 *  
 * This license applies to this entire compilation.
 */

import CID from 'cids'
import BlockService from 'ipfs-block-service'
import exporter, { Options as GetOptions } from 'ipfs-unixfs-exporter'
import * as IPFSRepo from 'ipfs-repo'
import * as Ipld from 'ipld'
import * as mergeOptions from 'merge-options'
import * as ipldDagCbor from 'ipld-dag-cbor'
import * as ipldDagPb from 'ipld-dag-pb'
import * as ipldRaw from 'ipld-raw'
import * as multihash from 'multihashes'
import * as multihashing from 'multihashing-async'
import levelmem from 'level-mem'
import Block from 'ipfs-block'

class FakeBitswap {
  constructor (libp2p, blockstore, options) {
    this._libp2p = libp2p
    this.blockstore = blockstore
  }
  
    /**
   * Fetch a given block by cid. If the block is in the local
   * blockstore it is returned, otherwise the block is added to the wantlist and returned once another node sends it to us.
   *
   * @param {CID} cid
   * @returns {Promise<Block>}
   */
  async get (cid) {
    for await (const block of this.getMany([cid])) {
      return block
    }
  }

  /**
   * Fetch a a list of blocks by cid. If the blocks are in the local
   * blockstore they are returned, otherwise the blocks are added to the wantlist and returned once another node sends them to us.
   *
   * @param {Iterable<CID>} cids
   * @returns {Promise<AsyncIterator<Block>>}
   */
  async * getMany (cids) {
    let pendingStart = cids.length

    for (const cid of cids) {
      const has = await this.blockstore.has(cid)
      
      pendingStart--
      if (has) {
        yield this.blockstore.get(cid)
        continue
      }

      /* MOD: Zippie fastpeer */
      try {
        let fastpeer = window.ipfs_fastpeer ? window.ipfs_fastpeer : 'https://global-ipfs-fp.dev.zippie.org'
        let res = await fetch(fastpeer + '/api/v0/block/get/' + cid.toString(), {cache: 'force-cache'})
        // it's likely we'll connect again soon
        var hint = document.createElement("link");
	hint.rel = "preconnect";
	hint.href = fastpeer
        document.head.appendChild(hint)
        document.head.removeChild(hint)
        if (res.status === 200) {
          let buf = Buffer.from(await res.arrayBuffer())
          let m = multihash.decode(cid.multihash)
          if (cid.multihash.equals(await multihashing(buf, m.code))) {
             let b = new Block(buf, cid)
             if (await this.blockstore.has(cid)) {
               console.log('*** would have added twice to blockstore ' + cid)
             } else {
               await this.blockstore.put(b)
             }
             yield b
          }
          console.info('data mismatch from fast peer fetching ' + cid.toString())
        }
      } catch (err) {
        console.log('Was unable to fetch cid', err)
      }
    }
  }
  async start() {
  }
}

class FakeP2P {
    constructor() { 
       this.peerInfo = {id: { toB58String: function() { return 'abcd' } }}
       this.handle = function () {}
       this.on = function () {}
       this.peerStore = { peers: { values: [].values } }
       this.contentRouting = { findProviders: function () { }, provide: function () {} }
    }
}

const normalizeCidPath = (path) => {
  if (Buffer.isBuffer(path)) {
    return new CID(path).toString()
  }
  if (CID.isCID(path)) {
    return path.toString()
  }
  if (path.indexOf('/ipfs/') === 0) {
    path = path.substring('/ipfs/'.length)
  }
  if (path.charAt(path.length - 1) === '/') {
    path = path.substring(0, path.length - 1)
  }
  return path
}

window.getDefaultIpldOptions = (blockService, options) => {
  options = options || {}

  return mergeOptions.call(
    // ensure we have the defaults formats even if the user overrides `formats: []`
    { concatArrays: true },
    {
      blockService: blockService,
      formats: [ipldDagCbor, ipldDagPb, ipldRaw]
    }, options)
}

export class MiniIPFS {
   constructor(memory) {
       this.store = new IPFSRepo('ipfs')
   }
   
   async start() {
       if (!await this.store.exists()) {
         console.log('mini-ipfs: initing store')
         await this.store.init({})
       }
       if (this.store.closed) {
         console.log('mini-ipfs: opening store')
         await this.store.open()
       }
       console.log('mini-ipfs: creating fake bitswap')
       const blockExchange = new FakeBitswap(new FakeP2P(), this.store.blocks)
       this.blockExchange = blockExchange
       console.log('mini-ipfs: starting fake bitswap')
       this.blockExchange.start()
       console.log('mini-ipfs: creating block service')
       const blockService = new BlockService(this.store)
       this.blockService = blockService
       this.blockService.setExchange(this.blockExchange)
       console.log('mini-ipfs: creating ipld')
       const ipld = new Ipld(window.getDefaultIpldOptions(blockService, {}))
       this.ipld = ipld
   }
   async * cat (ipfsPath, options) {
       options = options || {}

       ipfsPath = normalizeCidPath(ipfsPath)
    
       const file = await exporter(ipfsPath, window.ipfs.ipld, options)

       // File may not have unixfs prop if small & imported with rawLeaves true
       if (file.unixfs && file.unixfs.type.includes('dir')) {
          throw new Error('this dag node is a directory')
       }

      if (!file.content) {
          throw new Error('this dag node has no content')
      }
      yield * file.content(options)
    } 
}

